<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <p class="grey--text">
        Are you a participant in an event hosted on  {{ tenant.name}}? You can skip this part as it only applies for hosting events on  {{ tenant.name}}. Joining an event on  {{ tenant.name}} is always free. You might need to pay to register for the event with the event organizer though.
      </p>

      <h1>Simple pricing</h1>
      <p>
        We like to keep things simple. At  {{ tenant.name}} you pay a small fee for each participant in your virtual or hybrid event. In-person race results are free.
      </p>
      <p>
        Our pricing ranges between USD 1 and 3 per participant (in a virtual/hybrid challenge), depending on the size of your event. Each event gets access to <router-link to="/features">all our features</router-link>, regardless of the size.
        Please <router-link to="/contact">contact us</router-link> to get a quote specific to your situation (or for EUR and GBP pricing).
      </p>

      <h3>In-Person race results are free</h3>
      <p>
        Events with up to 5,000 participants can publish their in-person race results for free in our app and on our platform. This gives you access to all features (such as our free app, customizable leaderboards, push notifications, liking and commenting). <router-link to="/solutions/race-results">Learn more</router-link> 
      </p>

      <h3>Full-Service Package</h3>
      <p>
        By default you get access to our self-service backend to easily configure and manage your event. Add the Full-Service Package to your event to have us do the setup and support for your event. This is an optional one-time USD 250.
      </p>

      <h3>Custom Event App</h3>
      <p>
        Events and challenges can publish their event for free in <router-link to="/get">our app</router-link>. 
        Optionally, we can also create and publish customized and branded event apps. A custom event app allows you to fully customize the design and experience of the app for your event. Our apps are available for Android and iPhone. Please <router-link to="/contact">contact us</router-link> to learn more about custom event apps.
      </p>

      <h3>Single Sign-On and co-branding</h3>
      <p>
        We offer a co-branded (white-label) version of our platform for larger organizers. Please <router-link to="/contact">contact us</router-link> for all details.
      </p>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Schedule a call to discuss pricing for <u>your</u> event</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/request-demo">Request Demo</v-btn>
              <v-btn class="mb-4 mr-4" color="white" outlined to="/contact">Explore features</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <iframe src="https://cdn.forms-content.sg-form.com/bad6a860-8ea4-11ea-90d0-222d5e1f3e65" style="width:100%;border:0; height:400px;" noborder border="0" />

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>